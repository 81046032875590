import React from 'react';
import { graphql } from 'gatsby';
import ArticleTemplate, { ArticleType } from '../components/ArticleTemplate';
import Cookie from '../components/shared/Cookie';

const Article = ({ data }) => {
  const { wpPage: page, wp } = data;
  const article = {
    id: page.id,
    uri: page.uri,
    newsItem: {
      heroImage: page.heroImage.heroImage as any,
      imagePositioning: page.heroImage.imagePositioning,
      blocks: page.article.blocks,
    },
    seo: page.seo,
  };

  return (
      <ArticleTemplate
        article={article}
        articleType={ArticleType.article}
        siteMeta={wp.generalSettings}
        seo={page.seo}
      />
  );
};

export const pageQuery = graphql`
  query ArticleById($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      uri
      title
      heroImage {
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imagePositioning {
          vertical: verticalAdjustment
          horizontal: horizontalAdjustment
        }
      }
      article {
        blocks: article {
          ...ArticlePage_HeadingBlockFragment
          ...ArticlePage_TextBlockFragment
          ...ArticlePage_ImageBlockFragment
          ...ArticlePage_TableBlockFragment
        }
      }
      seo {
        title
        metaDesc
        canonical
        opengraphImage {
          sourceUrl
        }
      }
    }
    ...WordpressSiteMetadataFragment
  }
`;

export default Article;
